@import "../../styles/mixins";

.notFound {
  display: flex;
  flex-direction: column;
  margin-top: 124px;

  align-items: center;
  text-align: center;

  p {
    max-width: 345px;
  }

  a {
    margin-top: 40px;
    margin-bottom: 80px;
    max-width: 345px;
    width: 100%;
  }

  @include desktop() {
    margin-top: 180px;
    p {
      max-width: 370px;
    }

    a {
      max-width: 370px;
    }
  }
}
